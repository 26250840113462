<template>
    <div>
        <div class="page-title">颜创小商店</div>

        <p v-if="error" class="text-danger">{{ error }}</p>
        <a-affix :offset-bottom="0" v-if="app.yc_shop_id">
            <div class="shop-guid-footer">
                <button class="btn" @click="gotoReg">
                    <span>打开在线店铺</span>
                    <img src="/assets/icons/guid-arr.png" alt="">
                </button>
            </div>
        </a-affix>

    </div>
</template>

<script>
import api from "@/repo/api";
import auth from "@/auth";

export default {
    name: "Guid",
    props: {
        app: Object
    },
    data() {
        return {
            error: "",
        }
    },
    mounted() {
        if (!this.app.yc_shop_id) this.initShop()
    },
    methods: {
        gotoReg() {
            if (auth.userInfo().id === this.app.owner_id) {
                const token = localStorage['token']
                window.open(`https://shop.ycsd.work/shop/account/login?yc_token=${token}`)
            } else {
                window.open(`https://shop.ycsd.work/shop/account/login`)
            }

        },
        initShop() {
            api.post('/ast-app/init-shop', {}, data => {

                if (data.code !== 0) {
                    this.error = data.msg
                }

            })
        },
    }
}
</script>

<style scoped lang="less">
.shop-guid-footer {

}

.shop-guid {
    img {
        margin-bottom: 12px;
    }

}

.shop-guid-footer {
    background-color: #FFFFFF;
    padding: 25px 0;

    .btn {
        width: 650px;
        height: 53px;
        background: #333333;
        box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-left: 20px;
    }

    img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
    }


}
</style>